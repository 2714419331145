<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧城市</h1>
      <p style="text-indent: 2em">
        “新型智慧城市”是适应新型智慧城镇化建设，以信息化创新引领城市发展转型，全面推进新一代信息通信技术与城市发展融合创新，
        加快工业化、信息化、城镇化、农业现代化融合，提高城市治理能力现代化水平，实现城市可持续发展的新路径、新模式、新形态。
      </p>
    </div>
    <div class="survey">
      <h5>概况</h5>
      <div class="surveyTitle">新理念</div>
      <div>创新、协同、绿色、开放、共享的发展理念;</div>
      <div>以人为本的服务化理念;一切为了服务。</div>
      <div class="surveyTitle">新思维</div>
      <div>“互联网”思维,“物联网”思维,大数据思维;</div>
      <div>一切靠大数据说话,凭大数据决策。</div>
      <div class="surveyTitle">新方法</div>
      <div>系统科学与系统工程方法,</div>
      <div>“分级分类”逐步推进建设。</div>
      <div class="surveyTitle">新目标</div>
      <div>提高城市治理能力现代化水平,</div>
      <div class="surveyBottom">实现城市可持续发展。</div>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <div class="detail" @click="go(item.id)">查看详情</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/cityImage/组 220.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "数据中心",
          img: require("../../assets/assets/cityImg/img2.png"),
          text: "",
          data: [
            "城市大数据中心",
            "国家A级计算机机房",
            "国家三级顶保标准（Tier3+）",
            "超大机房",
            "快速部署",
            "高设备利用率...",
          ],
        },
        {
          id: 1,
          name: "时空大数据与云平台",
          img: require("../../assets/assets/cityImg/img3.png"),
          data: [
            "高效的海量数据自动化实景三维建模",
            "多源数据的融合与重构",
            "全域空间的一张图展示",
            "高精度、无缝位置服务",
            "新型产业驱动",
          ],
          text: "",
        },
        {
          id: 2,
          name: "运行指挥中心",
          img: require("../../assets/assets/cityImg/img4.png"),
          text: "整合各委办局的信息化系统,实现部门、区域之间的数据关联和共享,消除“信息孤岛” 对数据进行融合处理、深度挖掘、决策分析，是智慧城市的核心中枢。",
        },
        {
          id: 3,
          name: "智慧城市APP",
          img: require("../../assets/assets/cityImg/img5.png"),
          text1: "VR、AR等新型产业核心平台,",
          content:
            "数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务",
        },
        {
          id: 4,
          name: "平安城市",
          img: require("../../assets/assets/cityImg/img6.png"),
          text: "",
          data: [
            "治安监控前端",
            "乡村监控前端",
            "实景作战系统",
            "微卡口系统",
            "人脸识别系统",
            "综合视频监控共享平台...",
          ],
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/m_cityDetail",
        query: {
          cityId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/cityImg/header.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .survey {
    > h5 {
      height: 40px;
      background: #3651d8;
      border-radius: 0px;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      margin: 0;
      line-height: 40px;
      padding-left: 10px;
    }
    > div {
      background-color: #fff;
      height: 18px;
      font-size: 14px;
      font-weight: normal;
      color: #6b6b6b;
      text-align: center;
    }
    .surveyTitle {
      height: 22px;
      font-size: 16px;
      font-weight: normal;
      color: #1864e3;
      padding: 14px 0;
    }
    .surveyBottom {
      padding-bottom: 20px;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 242px;
      background: url("../../assets/image/cityImage/组 220.png") no-repeat;
      background-size: 100% 100%;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
